<template>
  <div class="">
    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O subdistrito de Bicas pertence ao Distrito de Camargos, que por
                sua vez faz parte do Município de Mariana-MG. Situa-se a 15 km
                de distância do núcleo urbano de Camargos, a 33 km do centro de
                Mariana e a 139 km de Belo Horizonte, capital do estado de Minas
                Gerais. A paisagem apresenta variedade de vegetação, com
                extensas áreas de mata, relevo montanhoso e grande quantidade de
                afloramentos rochosos. O Rio Gualaxo do Norte atravessa o
                território no sentido leste, e ao longo do rio encontram-se
                quedas d’água, com destaque para a Cachoeira de Bicas.
              </p>
              <p>
                A ocupação da região onde se encontra remonta ao século XVIII,
                motivada pela atividade de exploração de ouro. Entretanto,
                conforme Registro de Terras Públicas de 1855, as declarações
                existentes não fazem referência à mineração, havendo cinco
                propriedades com terras de culturas e matas.<sup>1</sup>
              </p>
            </template>
          </Card>
          <Card borderColor="#e06919" class="mt-2 mb-5">
            <template v-slot:conteudo>
              <p>
                Trata-se de uma comunidade com terrenos típicos rurais, de
                grandes extensões e utilizados para realização de atividades de
                agricultura (plantio e criação de animais). Do pequeno
                agrupamento de construções que provavelmente fizeram parte de um
                núcleo original, restaram somente ruínas da época anterior ao
                rompimento da barragem. Ou seja, antes do rompimento, no local
                havia as ruínas da antiga ocupação. Esse conjunto edificado é
                composto por remanescentes arquitetônicos de algumas moradias,
                de uma venda e da Capela de São Sebastião, além das estruturas
                íntegras de uma pequena central hidrelétrica (PCH), em
                funcionamento antes da destruição pela lama.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_bicas_01.png"
          />
          <p
            style="font-size: 0.6em; text-align: center; width: 100%"
            class="mr-md-2"
          >
            Vista da paisagem da região de Bicas, com relevo montanhoso coberto
            por mata e afloramentos rochosos Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_bicas_02.png"
          />
          <p
            style="font-size: 0.6em; text-align: center; width: 100%"
            class="mr-md-2"
          >
            Situação da Pequena Central Hidrelétrica após ser atingida pela lama
            de rejeitos da Barragem de Fundão. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A capela original, erguida no século XVIII ou XIX, foi
                construída com estrutura de madeira e vedação com tijolos de
                adobe. Foi substituída pela construção cujas ruínas se encontram
                atualmente no local, utilizando sistema construtivo
                contemporâneo em alvenaria de tijolos queimados. As ruínas da
                capela estão situadas em terreno que foi tomado pela mata, a
                meia encosta, em platô elevado cerca de um metro em relação à
                área onde estão as ruínas da antiga venda. O adro da capela é
                cercado por muro de tijolos maciços queimados, havendo um trecho
                em pedras, sendo a escadaria de acesso ao adro também em pedras.
                Do lado esquerdo da capela, há uma cruz e lápide de madeira,
                indicativo da área do antigo cemitério, e, do lado direito,
                ainda se encontram as peças de madeira do campanário (torre da
                igreja onde ficam os sinos).
              </p>
              <p>
                Da antiga venda, restaram a fundação de pedras e alguns esteios
                de madeira ainda aprumados, além de trechos de parede de
                tijolinhos que ruiu. Na fundação de pedras da venda, ficaram as
                marcas da lama, assim como nas árvores do entorno, testemunhando
                o nível que o rejeito alcançou no local. Ao lado da venda,
                restou uma estrutura de galinheiro, parcialmente encoberta por
                vegetação. As três ou quatro casas que havia no entorno da
                capela foram encobertas pela vegetação densa.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A Pequena Central Hidrelétrica (PCH) de Bicas, inicialmente
                denominada Usina Júlio Mourão Guimarães, foi inaugurada em 1942.
                A usina, instalada sobre o Rio Gualaxo do Norte, gerava energia
                para a cidade de Mariana e para o distrito de Passagem de
                Mariana, além de empresas mineradoras instaladas no município.
                Situava-se na Cachoeira de Bicas, utilizando seu potencial
                hidráulico, onde a força ou movimento da água move as turbinas,
                e a energia mecânica proveniente da água é transformada em
                energia elétrica.
              </p>
              <p>
                Na margem norte do Rio Gualaxo do Norte, em área de planície que
                antecede a encosta, até o final da década de 90 do século XX
                havia também um aglomerado de casas e fornos de olaria. Ali
                moravam pessoas de uma mesma família que compartilhavam as
                terras, criavam animais e plantavam.
              </p>
            </template>
          </Card>
          <Card borderColor="#e06919" class="mt-2 mb-5">
            <template v-slot:conteudo>
              <p>
                Apesar do Registro de Terras Públicas de 1855 de Bicas não
                conter indicação de exploração de ouro, no curso do Rio Gualaxo
                do Norte, a atividade de garimpo era realizada em tempos atuais
                entre Bicas e Ponte do Gama com destaque para a região
                denominada “Engenho Podre”. Em 1980, foi encontrada grande
                quantidade de ouro no local, que atraiu garimpeiros de várias
                comunidades tanto do município de Mariana, quanto de cidades
                próximas. Desta forma, assim como ocorria no período colonial,
                quando os povoados foram se formando e consolidando, em Bicas a
                atividade de extração de ouro no rio ocorria de forma paralela
                às atividades de plantio e criação de animais nos sítios e
                fazendas.
              </p>
              <p>
                A beleza da paisagem e seus recursos naturais atraía tanto os
                moradores quanto visitantes que nos rios e cachoeiras se
                banhavam — com ênfase para a Cachoeira de Bicas —, além de
                realizarem atividades de pesca para lazer e consumo próprio. Na
                comunidade, era tradicionalmente utilizado o jiqui para captura
                de peixes no rio, consistindo em um cesto alongado e afunilado
                feito com fibra de bambu.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_bicas_03.png"
          />
          <p
            style="font-size: 0.6em; text-align: center; width: 100%"
            class="mr-md-2"
          >
            Trecho do Rio Gualaxo na região de Bicas após o rompimento da
            Barragem de Fundão, com água barrenta. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_bicas_04.png"
          />
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <div class="container ml-md-5">
            <Card borderColor="#e06919" class="mb-5">
              <template v-slot:conteudo>
                <p>
                  As áreas de baixada ao longo do Rio Gualaxo do Norte foram
                  devastadas pela ação da lama, da mesma forma que o próprio
                  curso d’água e a vegetação de suas margens. As atividades
                  produtivas foram afetadas, até mesmo impossibilitadas, a
                  exemplo do garimpo. Nos terrenos alcançados pela lama, não é
                  mais possível cultivar nem mesmo criar animais nas extensões
                  de menor altitude tomadas pelo rejeito. A criação de animais
                  também foi dificultada mesmo nas cotas mais elevadas, visto
                  que a água dos corpos hídricos, o solo e o lençol freático
                  sofreram contaminação de metais pesados.
                </p>
                <p>
                  O rompimento da barragem destruiu completamente parte das
                  comunidades e atingiu setores de outras ao longo do território
                  do Gualaxo do Norte, quebrando assim a cadeia socioeconômica
                  que até então existia. Provocou a evasão populacional, gerando
                  dificuldade de contratação de mão de obra, além das relações
                  de compra e venda da produção. Em um primeiro momento, as
                  comunidades ficaram isoladas devido à destruição de pontes e
                  estradas e, mesmo quando o acesso foi retomado depois de longo
                  período, essa população se deparou com o isolamento
                  socioeconômico, mesmo com a reconstrução dos caminhos, visto
                  que já não tinham mais as relações comunitárias, ou seja, pra
                  quem vender, de quem comprar e quem contratar para prestar os
                  serviços. Somada à queda ou impossibilidade de produção, os
                  terrenos da região sofrem desde o rompimento da barragem com a
                  extrema desvalorização imobiliária e desinteresse por parte de
                  compradores.
                </p>
                <p style="text-align: center;">
                  <strong>Autoria: Ana Paula Alves Ferreira<sup>2</sup></strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center justify-md-start align-center">
        <v-col cols="12" md="8" class="">
          <div class="iframe__box">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/yVL_IPnnrtM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p style="font-size: 14px; text-align: center;">
            Vista de parte da estrutura que compunha a PCH de Bicas, no momento
            em que a lama de rejeitos chegou à comunidade. A PCH localizava-se
            há 10km da barragem de Fundão e em minutos foi completamente
            destruída, visto que a lama alcançou 15m de altura.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center justify-md-end align-center">
        <v-col cols="12" md="8" class="">
          <div class="iframe__box">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ialzsI0-zEQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p style="font-size: 14px; text-align: center;">
            Imagens dos elementos remanescentes da PCH de Bicas após a
            destruição da área pela lama de rejeitos, tratando-se da área da
            captação e o local onde se situava a edificação para abrigo das
            turbinas. À direita da edificação, hoje em ruínas, encontra-se a
            cachoeira de Bicas.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> As informações a respeito do histórico e da
              caracterização de Bicas tiveram como base principal o Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão, elaborado em
              2017 pela empresa Pólen Consultoria Patrimônio e Projetos Ltda. O
              Diagnóstico foi encomendado pela Comissão dos Atingidos e pela
              Cáritas Brasileira MG e juntamente com os dados do cadastro
              realizado pela Cáritas, possibilitou um entendimento mais amplo
              das relações culturais, sociais e econômicas que foram
              consolidadas nesse território ao longo dos séculos. O trabalho
              abrange aspectos geográficos, históricos, arqueológicos,
              arquitetônicos, antropológicos e museológicos. Contudo, ainda se
              trata de um estudo e diagnóstico preliminar, sendo necessário a
              ampliação do conhecimento sobre as comunidades atingidas.
            </p>
            <p>
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
.iframe__box {
  position: relative;
  padding-bottom: 56.1%;
  height: 0;
  overflow: hidden;
}

.iframe__box iframe,
.iframe__box object,
.iframe__box embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
